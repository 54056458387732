import React from 'react';
import { XCircle } from 'lucide-react';
import { Alert, AlertDescription, AlertTitle } from '../../components/ui/Alert';

const ErrorPage = () => {
  return (
    <div className="min-h-screen bg-gray-50 flex items-center justify-center p-4">
      <div className="max-w-md w-full space-y-8">
        <Alert className="bg-white border-red">
          <div className="flex justify-center mb-4">
            <XCircle className="h-12 w-12 text-red" />
          </div>
          <AlertTitle className="text-2xl font-semibold text-red text-center">
            Oops! Something went wrong
          </AlertTitle>
          <AlertDescription className="mt-2">
            We encountered an error while processing your request. Please try again or contact support if the problem persists.
          </AlertDescription>
          <div className="mt-6 flex justify-center space-x-4">
            <a href="https://recant.ai">
              <button className="bg-red text-white px-4 py-2 rounded-lg hover:bg-red transition-colors">
                Try Again
              </button>
            </a>
            <a href="mailto:cam@recant.ai">
              <button className="border border-red text-red px-4 py-2 rounded-lg hover:bg-lightGrey transition-colors">
                Contact Support
              </button>
            </a>
          </div>
        </Alert>
      </div>
    </div>
  );
};

export default ErrorPage;