import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import { useForm } from "react-hook-form";
import { apiClient } from "../../config/axios";
import RangeSlider from "../../common/RangeSlider";

const SensitivityParameter = forwardRef((props, ref) => {
  const { isEdit } = props;
  const { handleSubmit } = useForm();
  console.log(isEdit, "isEdit");
  const [sliders, setSliders] = useState([
    { label: "Sexually suggestive or explicit content.", value: 1 },
    { label: "Threatening or violent content.", value: 1 },
    { label: "Racism", value: 1 },
    { label: "Sexism", value: 1 },
    { label: "General Hate", value: 1 },
  ]);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await apiClient.get(`/api/user`);
        const userData = response.data;

        if (userData.categories && userData.categories.length > 0) {
          setSliders(userData.categories);
        }
      } catch (error) {
        console.error("Failed to fetch user data", error);
      }
    };

    fetchUserData();
  }, []);

  const onSetParameterSubmit = async (data) => {
    try {
      await apiClient.put(`/api/user`, { categories: sliders });
      console.log("Data updated successfully");
    } catch (error) {
      console.error("Update failed", error);
    }
  };

  const handleSliderChange = (index, newValue) => {
    const updatedSliders = sliders.map((slider, i) =>
      i === index ? { ...slider, value: Number(newValue) } : slider
    );
    setSliders(updatedSliders);
  };

  // Expose the `submit` and `getSliders` methods to the parent component via ref
  useImperativeHandle(ref, () => ({
    submit: () => handleSubmit(onSetParameterSubmit)(),
    getSliders: () => sliders, // Return sliders directly
  }));

  return (
    <div className='flex justify-center'>
      <form onSubmit={handleSubmit(onSetParameterSubmit)}>
        <div>
          <div className='mb-6 font-bold text-[15px]'>
            Please select your overall sensitivity level towards the following
            subjects.
          </div>
          <div className='flex flex-col gap-3'>
            {sliders.map((slider, index) => (
              <div
                key={index}
                className='flex flex-col relative gap-1 items-start'
              >
                <div className='mb-1 text-[14px]'>{slider.label}</div>
                <RangeSlider
                  isEdit={isEdit}
                  className='w-full text-primaryColor'
                  color={"#0A2B38"}
                  tooltipPlacement='top'
                  min={1}
                  max={10}
                  value={slider.value}
                  onChange={(e) => handleSliderChange(index, e.target.value)}
                />
              </div>
            ))}
          </div>
        </div>
      </form>
    </div>
  );
});

export default SensitivityParameter;
