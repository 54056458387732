import "react-toastify/dist/ReactToastify.css";
import "./AddProfile.css";
import {useState, useEffect} from "react";
import OnBoardingLayout from "../../../layouts/OnBoardingLayout";
import {useUser} from "../../DataContext";
import SocialMediaConnection from "../../../common/SocialMediaConnection";
import {apiClient} from "../../../config/axios";
import {createFormDataWithRepeatedKeys} from "../../../utils/CreateFormDataWithRepeatedKeys";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";
import {IoArrowBack} from "react-icons/io5";
import {Toaster} from "sonner";
import * as Frigade from '@frigade/react';
import {AnnouncementProps, Flow, Progress, Text} from "@frigade/react";
import {Button, Card, Dialog, Flex, studioTheme, ThemeProvider} from "@sanity/ui";

const AddProfile = () => {
  const {user} = useUser();
  const navigate = useNavigate();
  const [connectedProfile, setConnectedProfile] = useState(null);
  const [error, setError] = useState(null);
  const formData = createFormDataWithRepeatedKeys();
  // const { invitedToken, inviterUserId, message, loading } = useInviteToken();
  const fetchUserDetails = async () => {
    try {
      const {data} = await apiClient.get("api/user");
      setConnectedProfile(data);
    } catch (err) {
      setError(err);
    }
  };

  useEffect(() => {
    fetchUserDetails();
  }, []);

  useEffect(() => {
    const uploadDankestPhoto = async () => {
      const dankestPhotos = localStorage.getItem("uploadedProfilePicture");

      if (dankestPhotos && connectedProfile?.id) {
        try {
          await apiClient.post(
            `/api/troll-register/dankest/${connectedProfile.id}`,
            formData
          );
          toast.success("image successfully uploaded");
          localStorage.removeItem("uploadedProfilePicture");
        } catch (err) {
          setError(err);
        }
      }
    };

    if (connectedProfile) {
      uploadDankestPhoto();
    }
  }, [connectedProfile]);

  const updateEnterpriseMode = async (isEnterprise) => {
    try {
      await apiClient.put(
        `/api/user/is-enterprise`,
        JSON.stringify({isEnterprise})
      );
      toast.success("All set!");
    } catch (err) {
      setError(err);
    }
  }

  return (
    <OnBoardingLayout selected={1}>
      <Frigade.Provider apiKey={process.env.REACT_APP_FRIGADE_KEY} userId={user.id}>
        <Toaster/>
        <Frigade.Announcement
          flowId="flow_qiDFSt8d"
          onSecondary={() => updateEnterpriseMode(true)}
        />
        {/*<ThemeProvider theme={studioTheme}>*/}
        {/*  <Flow as={null} flowId={"flow_5GLhB4W4"}>*/}
        {/*    {({*/}
        {/*        flow,*/}
        {/*        handleDismiss,*/}
        {/*        handlePrimary,*/}
        {/*        handleSecondary,*/}
        {/*        parentProps: {dismissible},*/}
        {/*        step,*/}
        {/*      }) => (*/}
        {/*      <Dialog*/}
        {/*        __unstable_autoFocus={false}*/}
        {/*        header={step.title}*/}
        {/*        id="dialog-1"*/}
        {/*        onClose={*/}
        {/*          dismissible*/}
        {/*            ? () => {*/}
        {/*              // @ts-expect-error - handleDismiss expects an event to be passed to it*/}
        {/*              handleDismiss();*/}
        {/*            }*/}
        {/*            : undefined*/}
        {/*        }*/}
        {/*        zOffset={1000}*/}
        {/*        width={1}*/}
        {/*      >*/}
        {/*        <Flex*/}
        {/*          direction="column"*/}
        {/*          gap={4}*/}
        {/*          paddingRight={4}*/}
        {/*          paddingLeft={4}*/}
        {/*          paddingBottom={4}*/}
        {/*        >*/}
        {/*          {step.imageUri && (*/}
        {/*            <Card radius={4} className={""}>*/}
        {/*              <img src={step.imageUri} className={""}/>*/}
        {/*            </Card>*/}
        {/*          )}*/}

        {/*          <Text.Body2>{step.subtitle}</Text.Body2>*/}

        {/*          <Progress.Dots*/}
        {/*            current={flow.getCurrentStepIndex() + 1}*/}
        {/*            marginInline="auto"*/}
        {/*            total={flow.getNumberOfAvailableSteps()}*/}
        {/*          />*/}

        {/*          <Flex direction="row" gap={3}>*/}
        {/*            {step.primaryButton?.title && (*/}
        {/*              <Button*/}
        {/*                onClick={handlePrimary}*/}
        {/*                text={step.primaryButton.title}*/}
        {/*                tone="primary"*/}
        {/*                width="fill"*/}
        {/*              />*/}
        {/*            )}*/}
        {/*            {step.secondaryButton?.title && (*/}
        {/*              <Button*/}
        {/*                onClick={handleSecondary}*/}
        {/*                mode="ghost"*/}
        {/*                space={3}*/}
        {/*                text={step.secondaryButton.title}*/}
        {/*                width="fill"*/}
        {/*              />*/}
        {/*            )}*/}
        {/*          </Flex>*/}
        {/*        </Flex>*/}
        {/*      </Dialog>*/}
        {/*    )}*/}
        {/*  </Flow>*/}
        {/*</ThemeProvider>*/}
        <div className="bg-white w-full px-3 pt-3 h-screen mt-2">
          <button
            onClick={() => navigate("/")}
            className="flex items-center gap-2 hover:underline font-bold text-primaryColor hover:text-hoverBaseColor mb-4 ml-4"
          >
            <IoArrowBack size={20}/>
            <span>Back to Home</span>
          </button>
          <div className="flex flex-col items-center mt-6 lg:mt-24 md:w-8/12 w-full mx-auto ">
            <h1 className="text-2xl text-grey text-center font-semibold">
              Welcome {user?.firstName}!
            </h1>
            <p className="text-lg text-grey -mt-1 text-center">
              Add social media profiles to your Recant.ai account.
            </p>
          </div>
          <SocialMediaConnection/>
        </div>
      </Frigade.Provider>
    </OnBoardingLayout>
  );
};

export default AddProfile;
