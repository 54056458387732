import React from "react";
import {toast} from "sonner";


export default function DesktopDownloadSection() {


  const downloadPackage = (build) => {
    if (build === "windows") {
      toast.error("Windows is Coming Soon 😉")
      return;
    }

    const packageURL = `https://recant-desktop-packages.s3.ap-southeast-2.amazonaws.com/recant-desktop-${build}.zip`

    const link = document.createElement("a");
    link.href = packageURL;
    link.download = "recant-desktop"
    link.click();
  }

  return (

    <div>
      <div className={"mb-8 mt-12"}>
        <p className="text-lg text-grey text-center">
          Manage your conversations effortlessly with the Recant App (Beta).
        </p>
      </div>

      <div className={"grid grid-cols-2 gap-8"}>

        <button
          className="rounded-[10px] border-[1px] border-[#e2dfdf] flex flex-col items-center px-3  w-full hover:border-[#bcbaba] "
          onClick={() => downloadPackage("macos-amd64")}
        >
          <p className="text-[17px] text-[#787575] font-medium ml-4 !mb-0 px-2 pt-4 pb-4 ">
             macOS (Apple Silicon)
          </p>
        </button>

        <button
          className="rounded-[10px] border-[1px] border-[#e2dfdf] flex flex-col items-center px-3  w-full hover:border-[#bcbaba]"
          onClick={() => downloadPackage("macos-universal")}
        >
          <p className="text-[17px] text-[#787575] font-medium ml-4 !mb-0 px-2 pt-4 pb-4 ">
             macOS (Intel)
          </p>
        </button>

        <button
          className="rounded-[10px] border-[1px] border-[#e2dfdf] flex flex-col items-center px-3  w-full hover:border-[#bcbaba]"
          onClick={() => downloadPackage("windows")}
        >
          <p className="text-[17px] text-[#787575] font-medium ml-4 !mb-0 px-2 pt-4 pb-4 ">
            Windows
          </p>
        </button>

        <button
          className="rounded-[10px] border-[1px] border-[#e2dfdf] flex flex-col items-center px-3 w-full hover:border-[#bcbaba] hover:cursor-not-allowed"
        >
          {/*This will redirect to the App Store/Play Store URL rather than download anything*/}
          <p className="text-[17px] text-[#ada8a8] font-medium ml-4 !mb-0 px-2 pt-4 pb-4 ">
            iOS/Android (In Development)
          </p>
        </button>

      </div>

    </div>
  );
}