import axios from "axios";

export const apiClient = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URI,
});

apiClient.interceptors.request.use(
  async ({authorization = true, ...config}) => {
    if (authorization) {
      const accessToken = localStorage.getItem("token");
      if (accessToken) {
        config.headers = {
          token: accessToken,
        };
      }
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const stagingApiClient = axios.create({
  baseURL: process.env.REACT_APP_STAGING_BACKEND_URI,
});

stagingApiClient.interceptors.request.use(
  async ({authorization = true, ...config}) => {
    if (authorization) {
      const accessToken = localStorage.getItem("token");
      if (accessToken) {
        config.headers = {
          token: accessToken,
        };
      }
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
