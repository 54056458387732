import React from 'react';

export const Alert = ({ className, children }) => {
  return (
    <div className={`p-6 rounded-lg shadow-sm ${className}`}>
      {children}
    </div>
  );
};

export const AlertTitle = ({ className, children }) => {
  return (
    <h3 className={`text-lg font-medium leading-6 ${className}`}>
      {children}
    </h3>
  );
};

export const AlertDescription = ({ className, children }) => {
  return (
    <p className={`mt-2 text-sm ${className}`}>
      {children}
    </p>
  );
};